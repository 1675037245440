<template>
  <v-row 
    v-if="config"
    no-gutters
  >
    <v-col
      cols="12"
    >
    </v-col>
    <v-col
      cols="8"
      style="padding-right: 10px;"
    >
      <div style="background-color: white;">
        <v-data-table
          v-if="issuesManager"
          :headers="headers"
          :items="issuesManager"
          hide-default-footer
          class="elevation-0"
        >
          <template v-slot:[`item.ident`]="{ item }">
            <a @click="handleEdit(item.ID)">{{ getTranslation(item.Name, locale) }}</a>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            {{ getTranslation(item.Description, locale) }}
          </template>
        </v-data-table>
      </div>
    </v-col>
    <v-col
      cols="4"
    >
        <issues-form 
          :editedID="selectedID"
          :workspaceID="workspaceID"
          :locale="locale"
          :languages="config.languages"
          :onClose="handleCloseForm"
        />
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import apiDtouch from '@/services/apiDtouch'
import IssuesForm from './Form'
export default {
  components: {
    IssuesForm,
  },
  data: () => ({
    config: null,
    selectedID: null,
    issuesManager: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
    headers () {
      return [
          {
            text: this.$t('dtouch.request.name', this.locale),
            align: 'start',
            value: 'ident',
          },
          {
            text: this.$t('dtouch.request.description', this.locale),
            align: 'start',
            value: 'description',
          },
        ]
    },
  },
  mounted () {
    this.handleGetConfig()
    this.handleGetData()
  },
  methods: {
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetConfig () {
      apiDtouch.getItem (`v2/private/workspaces/${this.workspaceID}`)
        .then(response => {
          if (!response) return
          this.config = {
            languages: response.Languages ? response.Languages.split(',') : []
          }
        })
    },
    handleGetData () {
      apiDtouch.getAll (`v2/private/workspaces/${this.workspaceID}/issues-manager`)
        .then(response => {
          if (!response) return
          this.issuesManager = response.map(item => {
            return item
          })
        })
    },
    handleEdit (v) {
      this.selectedID = v
    },
    handleCloseForm (v) {
      this.selectedID = null
      if (v) this.handleGetData()
    },
  },
}
</script>


